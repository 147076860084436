@charset "utf-8";

$brand-color: #69A356;

// Import partials from the `minima` theme.
@import "minima";

// custom CSS
.post-content dd { margin-left: 2em; }

.post-content img.mugshot {
    box-shadow: 2px 2px 4px gray;
    border: 1px solid black;
    border-radius: 4px;
}

.site-footer .copyright {
    font-size: 12px;
    text-align: center;
}
.site-footer .copyright a {
    color: gray;
}
.site-footer .copyright a:visited {
    color: gray;
}
